import { useEffect, useState } from "react";
import { Socket } from "socket.io-client";
import CustomButton from "./CustomButton";

interface ICustomButtonInputProps {
    socket: Socket | undefined;
    hintText: string;
    label: string;
    socketIoEvent: string;
    selectedDevice: number | null;
    className: string;
    type: "read" | "readAll" | "write";
}

export default function CustomButtomInput(props: ICustomButtonInputProps) {
    const [textField, setTextField] = useState("");
    useEffect(() => {
        if (props.socket !== undefined) {
            props.socket.on("readTemperature", (data: { reg: number, data: ArrayBuffer }) => {
                if (props.socketIoEvent === "readTemperature") {
                    setTextField(buf2hex(data.data));
                }
            });
            props.socket.on("readSetTemperatureRegister", (data: { reg: number, data: ArrayBuffer }) => {
                if (props.socketIoEvent === "readSetTemperatureRegister") {
                    setTextField(buf2hex(data.data));
                }
            });
            props.socket.on("readStatusRegister", (data: { reg: number, data: ArrayBuffer }) => {
                if (props.socketIoEvent === "readStatusRegister") {
                    setTextField(buf2hex(data.data));
                }
            });
            props.socket.on("readMaxPwm", (data: { reg: number, data: ArrayBuffer }) => {
                if (props.socketIoEvent === "readMaxPwm") {
                    setTextField(buf2hex(data.data));
                }
            });
            props.socket.on("readVoltage", (data: { reg: number, data: ArrayBuffer }) => {
                if (props.socketIoEvent === "readVoltage") {
                    setTextField(buf2hex(data.data));
                }
            });
            // props.socket.on("readAll", (data: { reg: number, data: ArrayBuffer }[]) => {
            //     if (props.socketIoEvent === "readAll") {
            //         console.log(data);
            //         // setTextField(buf2hex(data.data));
            //     }
            // });
        }
        return () => {
            props.socket?.off('readTemperature');
            props.socket?.off('readSetTemperatureRegister');
            props.socket?.off('readStatusRegister');
            props.socket?.off('readMaxPwm');
            props.socket?.off('readVoltage');
        };
    }, [props.socketIoEvent, props.socket]);

    return (
        <div className="flex flex-row flex-auto space-x-1 w-full">
            <>
                {/* <label className="text-4xl" htmlFor={props.lable}>{props.hintText}</label> */}
                <div className="w-1/3">
                    <CustomButton label={props.label} socket={props.socket} data={textField} event={props.socketIoEvent} className={props.className} selectedDevice={props.selectedDevice} />
                </div>
                <div className="w-2/3">
                    < input id={props.label}
                        disabled={(props.selectedDevice !== null) && props.type === "write" ? false : true}
                        className="bg-slate-50 hover:bg-slate-100 disabled:bg-gray-500 text-black text-[0.7vw] py-2 px-1 w-full rounded"
                        value={textField}
                        onChange={e => setTextField(e.target.value)}
                        placeholder={props.hintText}
                    />
                </div>
            </>
        </div>
    );
}
function buf2hex(buffer: ArrayBuffer) { // buffer is an ArrayBuffer
    return [...Array.from(new Uint8Array(buffer))]
        .map(x => "0x" + x.toString(16).padStart(2, '0'))
        .join(' ');
}