import { Socket } from "socket.io-client";

interface ITemperatureButtonProps {
    sign: "+" | "-";
    socket: Socket | undefined;
    event: string;
    className: string;
    selectedDevice: number | null;
}

export default function TemperatureButton(props: ITemperatureButtonProps) {
    const ButtonClickHandler = () => {
        if (props.socket !== undefined) {
            // Write calls
            if (props.event === "increase") {
                props.socket.emit("increaseTemp", { id: props.selectedDevice });
            }
            if (props.event === "decrease") {
                props.socket.emit("decreaseTemp", { id: props.selectedDevice });
            }
        }
    };

    return (
        <>
            <button
                type="button"
                disabled={props.selectedDevice !== null ? false : true}
                className={`${props.className}`}
                onClick={ButtonClickHandler}>
                {props.sign}
            </button>
        </>
    );
}