import { useMemo } from "react";
import { Socket } from "socket.io-client";
import EnableButton from "./EnableButton";
import TemperatureButton from "./TemperatureButton";

interface IGridButtonProps {
    socket: Socket | undefined;
    displayNumber: number;
    found: boolean;
    setTemp: number;
    currentTemp: number;
    enabled: boolean;
}

export default function GridButton(props: IGridButtonProps) {
    const disabledClass = props.found ? "" : "pointer-events-none opacity-50";
    const maxTemp = parseInt(window.__RUNTIME_CONFIG__?.REACT_APP_MAX_TEMP || process.env.REACT_APP_MAX_TEMP!) || 80;
    const minTemp = parseInt(window.__RUNTIME_CONFIG__?.REACT_APP_MIN_TEMP || process.env.REACT_APP_MIN_TEMP!) || 0;

    const color = useMemo(() => {
        if (props.currentTemp !== undefined) {
            const redVal = Math.round(255 / (maxTemp - minTemp) * (props.currentTemp - minTemp));
            const blueVal = Math.round(255 / (maxTemp - minTemp) * (maxTemp - props.currentTemp));
            return `rgb(${redVal},0,${blueVal})`;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentTemp]);


    return (
        <div className={`BUTTON${props.displayNumber} flex flex-col flex-auto relative h-[12vmin] ${disabledClass} whitespace-nowrap`}>
            <div style={{ backgroundColor: `${color}` }} className={`hover:bg-gray-600 text-gray-300 font-semibold grow font-mono`}>
                <div className="ID text-[1.2vmin] absolute left-1/2 -translate-x-1/2 top-2">
                    ID: {props.displayNumber}
                </div>
                <div className="absolute text-[1.3vmin] top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 ">
                    Cur: {props.currentTemp}°C
                </div>
                <div className="absolute text-[1.3vmin] bottom-1/4 -translate-y-1/4 left-1/2 -translate-x-1/2">
                    Set: {props.setTemp}°C
                </div>
                <TemperatureButton
                    sign="+"
                    event="increase"
                    selectedDevice={props.displayNumber}
                    className={`absolute top-4 right-3 text-red-700 hover:text-red-500 text-[2.8vmin] font-bold font-mono`}
                    socket={props.socket}
                />
                <TemperatureButton
                    sign="-"
                    event="decrease"
                    selectedDevice={props.displayNumber}
                    className={`absolute bottom-1 right-3 text-blue-700 hover:text-blue-500 text-[2.8vmin] font-bold font-mono`}
                    socket={props.socket}
                />
                <EnableButton
                    socket={props.socket}
                    enabled={props.enabled}
                    selectedDevice={props.displayNumber}
                    className={`${props.enabled ? "bg-green-500" : "bg-red-500"} w-2 h-2 rounded-full absolute left-2 bottom-2`}
                />
            </div>
        </div >
    );
}