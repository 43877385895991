/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Socket } from "socket.io-client";

interface ICustomSelect {
    socket: Socket | undefined;
    deviceHandler: (device: number | null) => void;
}

export interface IDevice {
    address: number;
    id: number;
}

export default function CustomSelect(props: ICustomSelect) {
    const [list, setList] = useState<IDevice[]>([]);
    const [selectedDevice, setSelectedDevice] = useState<string | null | undefined>(null);
    const [deviceId, setDeviceId] = useState<number | null>(null);
    const ALL_INDEX = -1;

    useEffect(() => {
        console.log("selectedDevice", selectedDevice);
        if (selectedDevice === null) setDeviceId(null);
        if (selectedDevice === "All") setDeviceId(null);
    }, [selectedDevice]);

    useEffect(() => {
        if (props.socket !== undefined) {
            props.socket.on("deviceList", (list: IDevice[]) => {
                // console.log("deviceList", list);
                setList(list);
            });
        }
        return () => {
            props.socket?.off('deviceList');
        };
    }, [props, props.socket]);

    const createSelectList = () => {
        // If list is updated create new select object with new device list.
        const select = list.map((device: IDevice) => {

            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            return <a
                href="#"
                className="block px-4 py-2 text-gray-800 hover:bg-indigo-500 hover:text-white"
                key={device.address + device.id}
                onClick={() => {
                    console.log("Selected device: " + device.id);
                    setDeviceId(device.id);
                    setSelectedDevice(device.address.toString());
                    props.deviceHandler(device.id);
                    document.getElementById("options")?.classList.toggle("hidden");
                }}
            >
                {device.id}
            </a>;
        });
        // Add empty option to select list.

        // If list is empty create select with empty list.
        if (select.length === 0) {
            // select.push(<option key={0} value={0}>No devices</option>);
            select.push(<a key="no-devices" href="#" className="block px-4 text-gray-800">No devices</a>);
        } else {
            // select.unshift(<option key={0} value={0}>Select device</option>);
            select.unshift(<a
                href="#"
                className="block px-4 py-2 text-gray-800 hover:bg-indigo-500 hover:text-white"
                key={"all-devices"}
                onClick={() => {
                    console.log("All selected")
                    setSelectedDevice("All");
                    props.deviceHandler(ALL_INDEX);
                    document.getElementById("options")?.classList.toggle("hidden");
                }}
            >
                All
            </a>);
            select.unshift(<a
                href="#"
                className="block px-4 py-2 text-gray-800 hover:bg-indigo-500 hover:text-white"
                key="select-device"
                onClick={() => {
                    console.log("Nothing selected")
                    setSelectedDevice(null);
                    props.deviceHandler(null);
                    document.getElementById("options")?.classList.toggle("hidden");
                }}
            >
                Select device
            </a>);
        }
        return select;
    };

    const showDropdownOptions = () => {
        document.getElementById("options")?.classList.toggle("hidden");
        document.getElementById("arrow-up")?.classList.toggle("hidden");
        document.getElementById("arrow-down")?.classList.toggle("hidden");
    }

    return (
        <div className="text-left items-center">
            <label className="text-[1vw]"
                htmlFor={"select"}>
                {`${selectedDevice === `All`
                    ? `Selected: All`
                    : `${selectedDevice === null
                        ? "Selected ID:"
                        : `Selected ID:${deviceId} [I2C addr: 0x${selectedDevice}]`
                    }`
                    }`
                }
            </label>
            <div className="flex flex-row justify-center text-[0.8vw]">
                <div className="flex-none relative">
                    <button
                        onClick={showDropdownOptions}
                        className="flex flex-row justify-between w-[19vw] px-2
                        text-gray-700 bg-white border-2 border-white 
                        rounded-md shadow focus:outline-none focus:border-blue-600"
                    >
                        <span className="select-none">{deviceId ? deviceId : "Select display"}</span>
                        <svg
                            id="arrow-down"
                            className="hidden w-[1.3vw] h-[1.3vw] pt-1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20">
                            <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd" />
                        </svg>
                        <svg id="arrow-up"
                            className="w-[1.3vw] h-[1.3vw] pt-1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20">
                            <path
                                fillRule="evenodd"
                                d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                                clipRule="evenodd" />
                        </svg>
                    </button>
                    <div id="options" className="hidden absolute top-[1.3vw] w-[19vw] py-1 mt-2 bg-white rounded-md shadow-xl z-10 h-[50vh] overflow-auto">
                        {createSelectList()}
                    </div>
                </div>
            </div>
        </div>
    );
}