import { useEffect, useState } from 'react';
import io from "socket.io-client";
import CustomButton from './CustomButton';
import CustomButtomInput from './CustomButtomInput';
import CustomSelect from './CustomSelect';
import ConsoleElement from './ConsoleElement';
import DisplayGrid from './DisplayGrid';
import CustomInput from './CustomInput';

const socket = io(window.__RUNTIME_CONFIG__?.REACT_APP_API_URL || process.env.REACT_APP_API_URL || "", {
  path: window.__RUNTIME_CONFIG__?.REACT_APP_API_PATH || process.env.REACT_APP_API_PATH || "",
});
console.log(`Connecting to ${window.__RUNTIME_CONFIG__?.REACT_APP_API_URL || process.env.REACT_APP_API_URL || ""}${window.__RUNTIME_CONFIG__?.REACT_APP_API_PATH || process.env.REACT_APP_API_PATH || ""}`);
export interface Error {
  name: string;
  message: string;
  stack?: string;
}

function App() {
  // const [socket, setSocket] = useState<Socket>();
  const [selectedDevice, setSelectedDevice] = useState<number | null>(null);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [rowLen, setRowLen] = useState<number>(localStorage.getItem("rowLen") ? parseInt(localStorage.getItem("rowLen") || "1") : 8);
  const [displayCount, setDisplayCount] = useState<number>(localStorage.getItem("displayCount") ? parseInt(localStorage.getItem("displayCount") || "1") : 40);
  const [consoleText, setConsoleText] = useState<Error>();

  useEffect(() => {
    socket.on('connect', () => {
      setIsConnected(true);
      socket.emit("getDeviceList");
    });

    socket.on('disconnect', () => {
      setIsConnected(false);
    });

    socket.on("error", (error) => {
      console.error("Socket error", error);
      setConsoleText(error);
    });

    return () => {
      clearSockets();
    };
  }, []);

  const clearSockets = () => {
    socket.off('connect');
    socket.off('disconnect');
    socket.off('error');
  }

  const disabledClass = selectedDevice !== -1 ? "" : "pointer-events-none opacity-50";

  return (
    <body className="bg-gray-700 text-gray-300 min-h-screen">
      <header className="HEADER sticky z-50 shadow-md text-4xl ">
        <h1 className=' font-semibold text-center uppercase'>Warm surfaces Demo App</h1>
      </header>
      <main className='MAIN relative flex flex-row items-start space-x-5 px-5 divide-x divide-slate-500'>
        <div className="COLUMN1 text-center w-1/5 flex flex-col items-start justify-center space-y-2 h-[calc(100vh-6vh)] pb-5 ">
          <div className='INPUT-GROUP flex flex-row justify-start space-x-5 w-full'>
            <CustomInput value={rowLen} text={'Row length'} localStoreString="rowLen" setterFunction={setRowLen} />
            <CustomInput value={displayCount} text={'Display count'} localStoreString="displayCount" setterFunction={setDisplayCount} />
          </div>
          <div className='SELECT py-3 '>
            <CustomSelect socket={socket} deviceHandler={setSelectedDevice} />
          </div>
          {/* <div className=''>
            <CustomButton
              label={"Update Device List"}
              socket={socket}
              data={true}
              event={"getDeviceList"}
              selectedDevice={100}
              className={`bg-blue-200 hover:bg-blue-400`} />
          </div> */}
          <div className='flex flex-row flex-nowrap justify-center space-x-1 w-full'>
            <CustomButton
              label={"Set Enabled"}
              socket={socket}
              data={true}
              event={"setEnabled"}
              selectedDevice={selectedDevice}
              className={`bg-blue-200 hover:bg-blue-400`} />
            <CustomButton
              label={"Set Disabled"}
              socket={socket}
              data={false}
              event={"setEnabled"}
              className={`bg-red-200 hover:bg-red-400`}
              selectedDevice={selectedDevice} />
            <CustomButton
              label={"Read All"}
              socket={socket}
              data={null}
              event={"readAll"}
              className={`${disabledClass} bg-green-200 hover:bg-green-400`}
              selectedDevice={selectedDevice}
            />
          </div>
          <CustomButtomInput
            socket={socket}
            hintText={"Set desired temperature value here..."}
            label={'Set temperature'}
            socketIoEvent={'setTemperature'}
            selectedDevice={selectedDevice}
            className={`bg-blue-200 hover:bg-blue-400`}
            type={'write'} />
          {/* <CustomButtomInput
            socket={socket}
            hintText={"Set desired max PWM here..."}
            label={'Set max PWM'}
            socketIoEvent={'setMaxPwm'}
            selectedDevice={selectedDevice}
            className={`bg-blue-200 hover:bg-blue-400`}
            type={'write'} />
        */}
          <p className={`whitespace-nowrap text-[0.8vw] ${isConnected ? "" : "text-red-500"}`}>Backend connected: {'' + isConnected}</p>
          <ConsoleElement
            label={"Output"}
            socket={socket}
            socketIoEvent={''}
            content={consoleText}
            className={""} />
        </div>
        <div className="COLUMN2 w-4/5 pl-5 min-h-[calc(100vh-6vh)]" >
          <DisplayGrid rowLen={rowLen} displayCount={displayCount} socket={socket} />
        </div>
      </main>
    </body>
  );
}

export default App;
