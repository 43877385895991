import { useState, useEffect } from "react";
import { Socket } from "socket.io-client";
import { IDevice } from "./CustomSelect";
import GridButton from "./GridButton";
import { DEFAULT_ROW_COUNT, DEFAULT_ROW_LEN } from "./utils/constants";

interface IDisplayGridProps {
    rowLen: number;
    displayCount: number;
    socket: Socket | undefined;
}

interface ITemps {
    id: number,
    curTemp: number,
    setTemp: number
}

interface IEnabledList {
    id: number,
    address: number
}

export default function DisplayGrid(props: IDisplayGridProps) {
    const [rowCount, setRowCount] = useState<number>(DEFAULT_ROW_COUNT);
    const [rowLen, setRowLen] = useState<number>(DEFAULT_ROW_LEN);
    const [list, setList] = useState<IDevice[]>([]);
    const [temps, setTemps] = useState<ITemps[]>([]);
    const [enabledList, setEnabledList] = useState<IEnabledList[]>([]);
    useEffect(() => {
        setRowLen(props.rowLen);
        setRowCount(props.displayCount / props.rowLen);
        return () => {
        };
    }, [props.displayCount, props.rowLen]);

    useEffect(() => {
        if (props.socket !== undefined) {
            props.socket.on("deviceList", (list: IDevice[]) => {
                // console.log("deviceList", list);
                setList(list);
            });
            props.socket.on("temperatures", (temps) => {
                // console.log(temps);
                setTemps(temps);
            });
            props.socket.on("enabledDevices", (enabledList) => {
                // console.log(enabledList);
                setEnabledList(enabledList);
            });
        }
        return () => {
            props.socket?.off('deviceList');
            props.socket?.off('temperatures');
            props.socket?.off('enabledDevices');
        };
    }, [props, props.socket]);

    /** Create single row */
    const Row = (rowNumber: any) => {
        let row: any = [];

        for (let index: number = 0; index < rowLen; index++) {
            const displayNumber = rowNumber.column * rowLen + index + 1;
            let found = false;
            if (list.length > 0 && list[displayNumber - 1] !== undefined && list[displayNumber - 1].id === displayNumber) {
                found = true;
            }

            let _curTemp = 0;
            let _setTemp = 0;
            if (temps && temps.length > 0 && temps[displayNumber - 1] !== undefined && temps[displayNumber - 1].id === displayNumber) {
                _curTemp = temps[displayNumber - 1].curTemp;
                _setTemp = temps[displayNumber - 1].setTemp;
            }
            let _enabled = false;

            //check if display number is in enabled list
            if (enabledList && enabledList.length > 0) {
                for (let i = 0; i < enabledList.length; i++) {
                    if (enabledList[i].id === displayNumber) {
                        // console.log("found enabled display", displayNumber);
                        _enabled = true;
                        break;
                    }
                }
            }

            /** Create display elements */
            if (displayNumber <= props.displayCount) {
                row.push(
                    <GridButton socket={props.socket} displayNumber={displayNumber} found={found} key={`button_${displayNumber}`} setTemp={_setTemp} currentTemp={_curTemp} enabled={_enabled} />
                );
            }
        }
        return row;
    };

    /** Create grid with multiple rows */
    const Rows = () => {
        let column: any = [];
        for (let index: number = 0; index < rowCount; index++) {
            let inverStyle = index % 2 === 0 ? "flex-row" : "flex-row-reverse space-x-reverse";
            column.push(
                <div key={`row_${index}`} className={`ROW${index} flex ${inverStyle}  items-end space-x-1 space-y-1`}>
                    <Row column={index} />
                </div>
            );
        }
        return column;
    };

    return (
        <Rows />
    );
}