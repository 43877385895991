import { Socket } from "socket.io-client";

interface IEnableButtonProps {
    socket: Socket | undefined;
    className: string;
    selectedDevice: number | null;
    enabled: boolean;
}

export default function EnableButton(props: IEnableButtonProps) {
    const ButtonClickHandler = () => {
        if (props.socket !== undefined) {
            //if props.enabled is true, then we are disabling the device
            console.log(`Device: ${props.selectedDevice} enabled: ${!props.enabled}`);
            props.socket.emit("setEnabled", { id: props.selectedDevice, data: !props.enabled });
        }
    };

    return (
        <>
            <button
                type="button"
                disabled={props.selectedDevice !== null ? false : true}
                className={`${props.className}`}
                onClick={ButtonClickHandler}>
            </button>
        </>
    );
}