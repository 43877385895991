import { useEffect, useState } from "react";
import { Socket } from "socket.io-client";

interface IConsoleElementProps {
    socket: Socket | undefined;
    label: string;
    socketIoEvent: string;
    className: string;
    content: any;
}

export default function ConsoleElement(props: IConsoleElementProps) {
    // const [textField, setTextField] = useState("");
    // const [listElement, setListElement] = useState<JSX.Element[]>([<><li>DATA:</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li><li>Reg: 0 [0x0], Data: 0x01</li></>]);
    const [listElement, setListElement] = useState<JSX.Element[]>([]);
    useEffect(() => {
        if (props.socket !== undefined) {
            props.socket.on("readTemperature", (data: { reg: number, data: ArrayBuffer }) => {
                // setTextField(buf2hex(data.data));
            });
            props.socket.on("readSetTemperatureRegister", (data: { reg: number, data: ArrayBuffer }) => {
                // setTextField(buf2hex(data.data));
            });
            props.socket.on("readStatusRegister", (data: { reg: number, data: ArrayBuffer }) => {
                // setTextField(buf2hex(data.data));
            });
            props.socket.on("readMaxPwm", (data: { reg: number, data: ArrayBuffer }) => {
                // setTextField(buf2hex(data.data));
            });
            props.socket.on("readVoltage", (data: { reg: number, data: ArrayBuffer }) => {
                // setTextField(buf2hex(data.data));
            });
            props.socket.on("readAll", (data: { reg: number, data: ArrayBuffer }[]) => {
                console.log(data);
                // create list element with each byte separately as list item
                const listElement: JSX.Element[] = [<li>READ ALL reponse:</li>];
                data.forEach((element, i) => {
                    listElement.push(<li
                        key={`${Math.random()}_i`}>
                        Reg: {element.reg} [0x{element.reg.toString(16)}], Data: {buf2hex(element.data)}
                    </li>);
                });
                // setTextField(listItem);
                setListElement(listElement);

            });
        }
        return () => {
            props.socket?.off('readTemperature');
            props.socket?.off('readSetTemperatureRegister');
            props.socket?.off('readStatusRegister');
            props.socket?.off('readMaxPwm');
            props.socket?.off('readVoltage');
            props.socket?.off('readAll');
        };
    }, [props.socketIoEvent, props.socket]);

    useEffect(() => {
        if (props.content !== undefined) {
            const listElement: JSX.Element[] = [<li key={`err_header${Math.random()}`}>Error from backend:</li>];
            listElement.push(<li
                key={`err_${Math.random()}`}>
                <p key={`err_msg${Math.random()}`}>
                    Err: {JSON.stringify(props.content)}
                </p>
            </li>);
            setListElement(listElement);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.content]);



    return (
        <div className="flex-grow h-full bg-gray-500 disabled:bg-gray-500 w-full text-black py-6 px-4 text-[0.8vw] rounded list-none space-y-0.5 text-left break-words">
            {listElement}
        </div>
    );
}
function buf2hex(buffer: ArrayBuffer) { // buffer is an ArrayBuffer
    return [...Array.from(new Uint8Array(buffer))]
        .map(x => "0x" + x.toString(16).padStart(2, '0'))
        .join(' ');
}