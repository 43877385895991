interface ICustomInputProps {
    value: number;
    text: string;
    localStoreString: string;
    setterFunction: (rowLen: number) => void;
}

export default function CustomInput(props: ICustomInputProps) {
    return (
        <div className=''>
            <label
                htmlFor={props.text}
                className="block mb-2 text-[0.8vw] font-medium text-gray-900 dark:text-gray-300">
                {props.text}
            </label>
            <input type="number"
                id={props.text}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-[0.8vw] rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[3vw] h-8 pl-2"
                placeholder={props.value.toString()}
                value={props.value}
                onChange={(e) => {
                    const val = parseInt(e.target.value);
                    localStorage.setItem(props.localStoreString, val.toString());
                    props.setterFunction(val === 0 ? 1 : val);
                }}
                required
            />
        </div>
    );
}