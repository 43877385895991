import { Socket } from "socket.io-client";

interface ICustomButtonProps {
    label: string;
    socket: Socket | undefined;
    event: string;
    className: string;
    selectedDevice: number | null;
    data?: any;
}

export default function CustomButton(props: ICustomButtonProps) {
    const ButtonClickHandler = () => {
        console.log(`Button: "${props.label}" clicked. Sending props.event: "${props.event}, data: ${props.data}`);
        if (props.socket !== undefined) {
            // Read calls
            if (props.event === "readTemperature") {
                props.socket.emit("readTemperature", props.selectedDevice);
            }
            if (props.event === "readSetTemperatureRegister") {
                props.socket.emit("readSetTemperatureRegister", props.selectedDevice);
            }
            if (props.event === "readStatusRegister") {
                props.socket.emit("readStatusRegister", props.selectedDevice);
            }
            if (props.event === "readMaxPwm") {
                props.socket.emit("readMaxPwm", props.selectedDevice);
            }
            if (props.event === "readVoltage") {
                props.socket.emit("readVoltage", props.selectedDevice);
            }
            if (props.event === "readAll") {
                props.socket.emit("readAll", props.selectedDevice);
            }

            //Get found devices list
            if (props.event === "getDeviceList") {
                props.socket.emit("getDeviceList");
            }

            // Write calls
            if (props.event === "setTemperature") {
                props.socket.emit("setTemperature", { id: props.selectedDevice, data: props.data });
            }
            if (props.event === "setEnabled") {
                console.log(`Device: ${props.selectedDevice} enabled: ${props.data}`);
                props.socket.emit("setEnabled", { id: props.selectedDevice, data: props.data });
            }
            if (props.event === "setMaxPwm") {
                props.socket.emit("setMaxPwm", { id: props.selectedDevice, data: props.data });
            }
        }
    };

    return (
        <>
            <button
                type="button"
                disabled={props.selectedDevice !== null ? false : true}
                className={`${props.className} disabled:bg-gray-400 text-gray-700 text-[0.7vw] whitespace-nowrap py-2 px-2 w-full h-full overflow-clip rounded`}
                onClick={ButtonClickHandler}>
                {props.label}
            </button>
        </>
    );
}